import React, { useState, useEffect } from "react";
import { PostAuthRequest, getRecords } from "../../../../utills/common";
import { useForm } from "react-hook-form";
import { ProfileSidebar } from './ProfileSidebar';

export function Availbility({user_id, setActiveTab, NextStep, PrevStep, currentStep,
   profileData, notify , NotifySomeError}) {
   const {
      reset,
      register,
      handleSubmit,
      formState: { errors, isDirty, isSubmitting, isValid },
    } = useForm();
    
  const [uncomplete, setUnComplete] = useState(false);
  const [ToFromEmpty, setToFromEmpty] = useState(false);
  const [ProfileDataAvailable, setProfileDataAvailable] = useState();
  const [DisbaleLink, setDisbaleLink]     = useState(false);

  let weekDays = ['Monday', 'Tuesday','Wednesday','Thursday','Friday','Saturday', 'Sunday'];
  let availableTime =  ['OFF','Ask','Late','12 Midnight','1am','2am','3am','4am','5am','6am','7am','8am','9am','10am','11am','12Noon','1pm','2pm','3pm','4pm','5pm','6pm','7pm','8pm','9pm','10pm','11pm','12 Midnight'];
  let availableTimeOptFrom = "";
  let availableTimeOptTo = "";
  let isEmpty = '';

   useEffect(() => {
      setProfileDataAvailable(profileData?.availabilty_days)
   }, []);

   const handleSave = async (formData) => {
      try {        
            let dataArr =[];
           
            let i=0;
            await formData?.day.map( (item, index)=>{
               // Make Auto seleted Day, Like Monday 
               if(formData?.from[index]!="" || formData?.to[index]!=""){
                  item = true;
               }
                // Make Auto seleted Day, Like Monday 
               //console.log("From and to",  formData?.from[index], formData?.to[index]);
               let obj = {
                  day:item,
                  from:formData?.from[index],
                  to:formData?.to[index]
               }
               if(item===true && (formData?.from[index]=="" || formData?.to[index]=="")){
                  setToFromEmpty(true)
                  isEmpty = true;
                  NotifySomeError("Please select From and To for availablity days")  
                  
               }else{
                  setToFromEmpty(false)
               }

               if(item===false){
                  i++;
               }

               dataArr.push(obj);
            })

            // New code 24-12-2024
            if(formData?.day.length===i){
               isEmpty = true;
               NotifySomeError("Please select at least one availablity days")
               return false;
            }
             // New code 24-12-2024

         if(isEmpty===true){
            return false;
         }else{
            let params = {availabilty_days:dataArr, user_id:formData.user_id, available_status:formData.available_status, profile_step:formData.profile_step}
            if(isValid){               
               const Res = await PostAuthRequest('user/save-profile', params);
               if(Res.success){
                     handleNext();
               }else{
                  console.error(Res.message);
               } 
            }      
            handleNotify(); // Notify the Save data
         }

         //   let final = { day:formData.day, from: formData.from, to:formData.to }

      } catch (error) {
        console.error(error.message);
      }
   };

    const handleNext = async (e) => {
      try {
        NextStep();
      } catch (error) {
        console.error(error.message);
      }
    };
  
    const handlePrev = (e) => {
      PrevStep();
    };


   const inArray = (needle, haystack) => {
      var length = haystack.length;
      for(var i = 0; i < length; i++) {
          if(haystack[i] == needle)
              return true;
      }
      return false;
   }

   const handleNotify= (e) => {
      notify("Profile step saved successfully."); // Notify the Save data
   }


   const availableTimeOptFromFun = (index) => {
      if (profileData?.availabilty_days!=null) {
         let selectedItem ='';
         availableTimeOptFrom =  availableTime?.map(function(time) {
            selectedItem = ( profileData?.availabilty_days[index].from==time ) ? true : false ;
               return <>
                  { 
                     selectedItem? 
                        <option value={time} selected={true} >
                           {time}
                        </option> : 
                        <option value={time} >
                           {time}
                        </option> 
                  }
               </> 
         })       
         return availableTimeOptFrom;
     }else{
         availableTimeOptFrom =  availableTime?.map(function(time) {         
            return <>
                     <option value={time} >
                        {time}
                     </option> 
              
            </> 
      })       
      return availableTimeOptFrom;
     }
   }


   const availableTimeOptToFun = (index) => {
      if (profileData?.availabilty_days!=null) {
         let selectedItem ='';
         availableTimeOptTo =  availableTime?.map(function(time) {
            selectedItem = ( profileData?.availabilty_days[index].to==time ) ? true : false ;
               return <>
                  { 
                     selectedItem? 
                        <option value={time} selected={true} >
                           {time}
                        </option> : 
                        <option value={time} >
                           {time}
                        </option> 
                  }
               </> 
         })       
         return availableTimeOptTo;
     }else{
         availableTimeOptTo =  availableTime?.map(function(time) {
               return <>
                  { 
                    
                        <option value={time} >
                           {time}
                        </option> 
                  }
               </> 
         })       
         return availableTimeOptTo;
     }
   }

   const availableDayFun = (index) => {
      return profileData?.availabilty_days && profileData?.availabilty_days[index]?.day? true : false ;
   }


   return (
      <>
         <form
            action="#"           
            className="nk-wizard nk-wizard-simple is-vertical is-alter wizard clearfix"
         >
            
            <ProfileSidebar setActiveTab={setActiveTab} currentStep={currentStep} profileData={profileData} />

            <div className="content clearfix">
               <div
                  className="nk-wizard-head title"
                  id="wizard-02-h-1"
                  tabindex="-1"
               >
                  <div className="step-text">
                     <div className="lead-text">Your Availability</div>
                     <div className="sub-text">Enter your working hours</div>
                  </div>
               </div>
               <div
                  className="nk-wizard-content timeform body"
                  id="wizard-02-p-1"
                  role="tabpanel"
                  aria-labelledby="wizard-02-h-1"
                  aria-hidden="true"
               >
                  <div className="mb-5">
                     <h5 className="title mb-2 text-primary">
                        Your Availability
                     </h5>                     
                     <p className="text-soft">
                           If you find yourself working late on any particular
                           day, be sure to log in and select the designated
                           option.
                        </p>
                  </div>
                  <div className="row gy-4">
                  
                     <div className="col-sm-12">
                        
                        {/* <span className="preview-title-lg overline-title">
                           Your Availability
                        </span> */}
                        
                        <div
                           className="alert alert-icon alert-danger"
                           role="alert"
                        >
                           <em className="icon ni ni-alert-circle"></em>
                           <strong>Important:</strong> You MUST log in every day
                           and tick this switch when you are available or not
                           available.
                        </div>
                        <div className="row gy-4">
                           <div className="col-sm-3">
                              <div className="form-group">
                                 <div className="form-control-wrap ">
                                    <div className="custom-control custom-switch">
                                       <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="availablenow"
                                          {...register("available_status[]", {
                                             required: false,
                                           })}
                                           value="Available Now"
                                           defaultChecked={ profileData?.available_status && inArray('Available Now',profileData.available_status)? true : false }
                                          //  checked={( profileData && profileData.available_status.filter(item => item=="Available Now")? true : false )  }
                                       />
                                       <label
                                          className="custom-control-label"
                                          htmlFor="availablenow"
                                       >
                                          Available Now
                                       </label>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {/* <div className="col-sm-3">
                              <div className="form-group">
                                 <div className="form-control-wrap ">
                                    <div className="custom-control custom-switch">
                                       <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="notavailable"
                                          {...register("available_status[]", {
                                             required: false,
                                           })}
                                           value="Not Available"
                                           defaultChecked={ profileData.available_status && inArray('Not Available',profileData.available_status)? true : false }
                                       />
                                       <label
                                          className="custom-control-label"
                                          htmlFor="notavailable"
                                       >
                                       Not Available
                                       </label>
                                    </div>
                                 </div>
                              </div>
                           </div> */}
                           <div className="col-sm-3">
                              <div className="form-group">
                                 <div className="form-control-wrap ">
                                    <div className="custom-control custom-switch">
                                       <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="incall"
                                          {...register("available_status[]", {
                                             required: false,
                                          })}
                                          value="In Call"
                                          defaultChecked={ profileData.available_status && inArray('In Call',profileData.available_status)? true : false }
                                       />
                                       <label
                                          className="custom-control-label"
                                          htmlFor="incall"
                                       >
                                          In Call
                                       </label>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-3">
                              <div className="form-group">
                                 <div className="form-control-wrap ">
                                    <div className="custom-control custom-switch">
                                       <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="outcall"
                                          {...register("available_status[]", {
                                             required: false,
                                          })}
                                          value="Out Call"
                                          defaultChecked={ profileData.available_status && inArray('Out Call',profileData.available_status)? true : false }
                                          
                                       />
                                       <label
                                          className="custom-control-label"
                                          htmlFor="outcall"
                                       >
                                          Out Call
                                       </label>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-12">
                        <hr className="preview-hr" />
                        <span className="preview-title-lg overline-title">
                           Your Availability Days
                        </span> 
                        <p>
                           Change any time to suit as required. If you do not enter
                           your working hours they will NOT show!
                        </p>
                        <div className="row mb-2">
                           <div className="col-sm-4">
                              <div className="form-control-wrap ">
                                 <label
                                    className="overline-title title"
                                    htmlFor="days"
                                 >
                                    Days
                                 </label>
                              </div>
                           </div>
                           <div className="col-sm-8">
                              <div className="form-group">
                                 <div className="form-control-wrap">
                                    <div className="input-group">
                                       <div className=" w-30">
                                          <label
                                             className="overline-title title"
                                             htmlFor="from"
                                          >
                                             From
                                          </label>
                                       </div>
                                       <div className="input-group-addon"></div>
                                       <div className="w-30">
                                          <label
                                             className="overline-title title"
                                             htmlFor="to"
                                          >
                                             To
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        { weekDays.map(function(day, index) {
                          return <div className="row mb-2">                             
                                    <div className="col-sm-4">
                                       <div className="form-control-wrap ">
                                          <div className="custom-control custom-switch checked">                                          
                                             <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={day}
                                                {...register("day["+index+"]", {
                                                   required: false,
                                                 })}
                                                 defaultChecked= { availableDayFun(index) }
                                             />
                                             <label
                                                className="custom-control-label form-label"
                                                htmlFor={day}
                                             >
                                                {day}
                                                
                                             </label>
                                             {/* { profileData?.availabilty_days[0].day } */}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-sm-8">
                                       <div className="form-group">
                                          <div className="form-control-wrap">
                                             <div className="input-group">
                                                <div className="form-control-select w-30">
                                                   <select
                                                      name=""
                                                      className="form-control"
                                                      {...register("from["+index+"]", {
                                                         required: false,
                                                      })}
                                                   >
                                                      <option value="">From</option>
                                                      { availableTimeOptFromFun(index) }
                                                      
                                                      
                                                   </select>
                                                </div>
                                                <div className="input-group-addon">
                                                   -
                                                </div>
                                                <div className="form-control-select w-30">
                                                   <select
                                                      name=""
                                                      className="form-control"
                                                      {...register("to["+index+"]", {
                                                         required: false,
                                                       })}
                                                   >
                                                      <option value="">To</option>
                                                      { availableTimeOptToFun(index) }

                                                      {/* { availableTime?.map(function(time) {
                                                        return <option value={time}>
                                                               {time}
                                                         </option>
                                                      })} */}
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 
                           </div>  
                        })}     
                     {
                        ToFromEmpty && <span className="invalid" >To and From are required if any day is mark checked.</span>
                     }
                        

                     </div>
                  </div>
               </div>
            </div>

         <div className="actions clearfix">
          <ul role="menu" aria-label="Pagination">
            {currentStep > 1 && (
              <li className="" aria-disabled="false">
                <a href="#previous" role="menuitem" onClick={handlePrev}>
                  Prev
                </a>
              </li>
            )}
            {currentStep < 7 && (
              <li aria-hidden="false" aria-disabled="false">
                <a
                  href="#next"
                  role="menuitem"
                  onClick={handleSubmit(handleSave)}
                  // className={uncomplete}
                  className={ (DisbaleLink)? "disabled-link "+uncomplete : uncomplete}
                >
                  Next
                </a>              
              </li>
            )}
            {currentStep == 7 && (
              <li aria-hidden="true">
                <a href="#finish" role="menuitem">
                  Activate My Account
                </a>
              </li>
            )}
          </ul>
         </div>
         <input type="hidden" value={user_id} {...register("user_id")} />
         <input type="hidden" value={currentStep} {...register("profile_step")} />
         </form>
      </>
   );
}
